import styles from './featuredSection.module.css';

interface Props {
	children: React.ReactNode;
}

function FeaturedSection({ children }: Props) {
	return (
		<div className={styles.sectionWrapper}>
			<div className={styles.topBgImage}></div>
			<div className={styles.contentWrapper}>
				<div className={styles.content}>{children}</div>
			</div>
			<div className={`${styles.topBgImage} ${styles.bottomBgImage}`}></div>
		</div>
	);
}

export default FeaturedSection;
