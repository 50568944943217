import styles from './formSection.module.css';

interface Props {
	children: React.ReactNode;
}

function FormSection({ children }: Props) {
	return <div className={styles.content}>{children}</div>;
}

export default FormSection;
