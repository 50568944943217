'use client';

import Link from 'next/link';
import { BannerItem } from './BannerItem';
import styles from './bannerSection.module.css';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { usePathname } from 'next/navigation';
import { MouseEvent } from 'react';
import { useSession } from 'next-auth/react';
export const BannerSection = () => {
	const { navigateTo, isAnimating } = useTransitionContext();
	const currentPath = usePathname();
	const { data: session } = useSession();
	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}
	return (
		<section className={styles.banner}>
			<Link
				href={!session?.user?.id ? '/contacto' : '/micarta'}
				onClick={(e) => {
					if (!session?.user?.id) handleClick(e, '/contacto');
				}}>
				<div className={styles.slide}>
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
				</div>
				<div className={styles.slide}>
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
					<BannerItem text={session?.user?.id ? 'Accede al panel' : 'Crea tu carta'} />
				</div>
			</Link>
		</section>
	);
};
