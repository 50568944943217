// Curve.tsx
'use client';

import React, { useEffect, useState } from 'react';
import { motion, Variants, useReducedMotion } from 'framer-motion';
import { curve, translate, text } from './anim';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { useFirstLoad } from '@/contexts/FirstLoadContext';

interface CurveProps {
	children: React.ReactNode;
}

const anim = (variants: Variants, isAnimating: boolean, isFirstLoad: boolean, handleAnimationComplete: () => void) => ({
	variants,
	initial: 'initial',
	animate: isFirstLoad ? 'initial' : isAnimating ? 'exit' : 'enter',
	onAnimationComplete: handleAnimationComplete
});

function Curve({ children }: CurveProps) {
	const { isAnimating, endTransition, pageName, isUpToAnimate } = useTransitionContext();
	const { isFirstLoad } = useFirstLoad();
	const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0
	});

	const prefersReducedMotion = useReducedMotion();

	useEffect(() => {
		function handleResize() {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}
		const resizeObserver = new ResizeObserver(handleResize);
		resizeObserver.observe(document.body);

		// Llamar a handleResize inmediatamente para establecer dimensiones iniciales
		handleResize();

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const handleAnimationCompleteWrapper = () => {
		if (isAnimating) {
			endTransition();
		}
	};

	return (
		<div className="curve" aria-hidden={false}>
			{/* Bloquear interacciones durante la animación */}
			{isAnimating && <div className="pointer-overlay"></div>}
			{!isUpToAnimate && <div className="pointer-overlay"></div>}
			{/* Renderizar las animaciones solo si no es la primera carga */}
			{!isFirstLoad && !prefersReducedMotion && (
				<>
					<div className="route-container">
						<motion.p className="route" {...anim(text, isAnimating, isFirstLoad, handleAnimationCompleteWrapper)}>
							{pageName}
						</motion.p>
					</div>
					{dimensions.width !== 0 && (
						<SVG
							width={dimensions.width}
							height={dimensions.height}
							isAnimating={isAnimating}
							isFirstLoad={isFirstLoad}
							handleAnimationComplete={handleAnimationCompleteWrapper}
						/>
					)}
				</>
			)}
			{children}
		</div>
	);
}

interface SVGProps {
	height: number;
	width: number;
	isAnimating: boolean;
	isFirstLoad: boolean;
	handleAnimationComplete: () => void;
}

const SVG = ({ height, width, isAnimating, isFirstLoad, handleAnimationComplete }: SVGProps) => {
	const initialPath = `M0 150 
    Q${width / 2} 0 ${width} 150
    L${width} ${height + 150}
    Q${width / 2} ${height + 600} 0 ${height + 150}
    L0 0`;

	const targetPath = `M0 150
    Q${width / 2} 0 ${width} 150
    L${width} ${height}
    Q${width / 2} ${height} 0 ${height}
    L0 0`;
	return (
		<motion.svg {...anim(translate, isAnimating, isFirstLoad, handleAnimationComplete)} className="svg">
			<motion.path
				{...anim(curve(initialPath, targetPath), isAnimating, isFirstLoad, handleAnimationComplete)}
				fill="#101010"
			/>
		</motion.svg>
	);
};

export default Curve;
