// AppProviders.tsx
'use client';

import { ReactNode } from 'react';
import FirstLoadContextProvider from '@/contexts/FirstLoadContext';
import { FirstLoadHandler } from '@/components/(animations)';
import { TransitionProvider } from '@/contexts/TransitionContext';
import { Curve } from '@/components/(animations)';

export default function AppProviders({ children }: { children: ReactNode }) {
	return (
		<FirstLoadContextProvider>
			<FirstLoadHandler>
				<TransitionProvider>
					<Curve>{children}</Curve>
				</TransitionProvider>
			</FirstLoadHandler>
		</FirstLoadContextProvider>
	);
}
