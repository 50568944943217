import Image from 'next/image';
import styles from './bannerItem.module.css';

export const BannerItem = ({ text }: { text: string }) => {
	return (
		<div className={styles.bannerItem}>
			<Image
				className={styles.bannerSectionImage}
				width={54}
				height={54}
				src={'/vectores/star.svg'}
				alt="Estrella abstracta."
			/>
			<span className={styles.bannerSectionSpan}>{text}</span>
		</div>
	);
};
