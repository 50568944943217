// anim.ts
import { Variants } from 'framer-motion';

export const text: Variants = {
	initial: {
		opacity: 1
	},
	enter: {
		opacity: 0,
		top: -100,
		transition: { duration: 0.75, delay: 0.35, ease: [0.76, 0, 0.24, 1] },
		transitionEnd: { top: '47.5%' }
	},
	exit: {
		opacity: 1,
		top: '40%',
		transition: { duration: 0.5, delay: 0.4, ease: [0.33, 1, 0.68, 1] }
	}
};

export const curve = (initialPath: string, targetPath: string): Variants => {
	return {
		initial: {
			d: initialPath
		},
		enter: {
			d: targetPath,
			transition: { duration: 0.75, delay: 0.35, ease: [0.76, 0, 0.24, 1] }
		},
		exit: {
			d: initialPath,
			transition: { duration: 0.75, ease: [0.76, 0, 0.24, 1] }
		}
	};
};

export const translate: Variants = {
	initial: {
		y: -150 // Posición inicial desplazada 150px hacia arriba
	},
	enter: {
		y: '-100vh', // Desplazamiento hacia arriba fuera de la vista
		transition: { duration: 0.75, delay: 0.35, ease: [0.76, 0, 0.24, 1] },
		transitionEnd: {
			y: '100vh' // Desplazamiento hacia abajo fuera de la vista
		}
	},
	exit: {
		y: -150, // Regreso a la posición inicial
		transition: { duration: 0.75, ease: [0.76, 0, 0.24, 1] }
	}
};
