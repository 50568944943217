import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(paginas)/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"600\",\"700\"]}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(paginas)/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"600\",\"700\"],\"variable\":\"--plus-jakarta-sans\"}],\"variableName\":\"plus_jakarta_sans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(paginas)/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/(layout)/adminHeader/AdminHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/components/(layout)/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/(layout)/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/nonReusableComponents/appProviders/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
