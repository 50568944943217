import styles from './sectionHeading.module.css';

interface Props {
	title: string;
	subtitle: string;
	variant: string;
}
function SectionHeading({ title, subtitle, variant }: Props) {
	if (variant === 'primary') {
		return (
			<div className={`${styles.heading} ${styles.headingPrimary}`}>
				<p className={styles.subtitle}>{subtitle}</p>
				<h1 className={styles.title}>{title}</h1>
			</div>
		);
	}
	if (variant === 'regular') {
		return (
			<div className={`${styles.heading} ${styles.headingRegular}`}>
				<p className={styles.title}>{subtitle}</p>
				<h1 className={styles.subtitle}>{title}</h1>
			</div>
		);
	}
}

export default SectionHeading;
